export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hour = d.getHours(),
        minutes = d.getMinutes();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    if (hour < 10) 
        hour = '0' + hour;
    if (minutes < 10)
        minutes = '0' + minutes;      

    let formatedDate = [day, month, year].join('-');
    let time = [hour, minutes].join(':');

    return [formatedDate, time].join(' ');
}

export function stringArray(data,label) {
    var stringRes = ''
    if(data){
        data.forEach(item => {
            stringRes = stringRes.concat(",", item[label]);
        })
        return stringRes.substring(1)
    }
    return ''
}

export function csvmaker(data) {
    // Empty array for storing the values
    var csvRows = [];
    
    // Headers is basically a keys of an
    // object which is id, name, and
    // profession
    var headers = Object.keys(data[0]);
    // As for making csv format, headers
    // must be separated by comma and
    // pushing it into array
    csvRows.push(headers.join(';'));

    // Pushing Object values into array
    // with comma separation
    var values = ''
    data.forEach(item => {
        item['abstract'] = item['abstract'].replace(/;/g,' ').replace(/\n/g,' ')
        item['title'] = item['title'].replace(/;/g,' ').replace(/\n/g,' ')
        values = Object.values(item).join(';')
        csvRows.push(values)
    })

    // Returning the array joining with new line
    return csvRows.join('\n')
}

export function getSelectedSensorId(getters) {
    var sensor_type = getters['sensor_type']
    var sensor_id = 1
    if (sensor_type == 'correntimetro')
      sensor_id = getters['sensor_selected']['correntimetro']
    else if (sensor_type == 'scl' || sensor_type == 'scl-acceleration' )
      sensor_id = getters['sensor_selected']['sensor_red']
    else if (sensor_type == 'sensor-carga')
      sensor_id = getters['sensor_selected']['sensor_carga']
    else if (sensor_type == 'sensor-carga-sat')
      sensor_id = getters['sensor_selected']['sensor_carga_sat']
    else if (sensor_type == 'sensor-boya')
      sensor_id = getters['sensor_selected']['sensor_boya']
    return sensor_id
      
}

export function commitData(commit,sensor_type,results) {
    switch (sensor_type) {
        case 'correntimetro':
          commit("set_correntimetro_data", results)
          break;
        case 'scl':
          commit("set_scl_data", results)
          break;
        case 'scl-acceleration':
          commit("set_acceleration_data", results)
          break;
        case 'sensor-carga':
          commit("set_sensor_carga", results)
          break;
        case 'sensor-carga-sat':
          commit("set_sensor_carga_sat", results)
          break;
        case 'sensor-boya':
          commit("set_sensor_boya", results)
          break;
      }
}

var rangos = [0, 40925, 73873,82197 ,Infinity];
var rangosNegativos = [0, -40925, -73873,-82197,-Infinity];
var colores = ['#1EEE78', '#E9B500', '#F86868','#7c7c7c'];

export function asignarColor(valor) {
    for (var i = 0; i < rangos.length - 1; i++) {
      if (valor >= rangos[i] && valor <= rangos[i + 1]) {
        return colores[i];
      }
    }
    for (var i = 0; i < rangosNegativos.length - 1; i++) {
        if (valor <= rangosNegativos[i] && valor >= rangosNegativos[i + 1]) {
          return colores[i];
        }
    }
    return 'gray'; // Por defecto, en caso de que no coincida con ningún rango
  }