import axios from 'axios'
import Vue from "vue";

var server_path = ''
if (process.env.VUE_APP_API_TYPE == 'local')
  server_path = process.env.VUE_APP_API_HTTP_S + '://' + process.env.VUE_APP_API_HOST + ':' + process.env.VUE_APP_API_PORT + '/';
else
  server_path = process.env.VUE_APP_API_HTTP_S + '://' + process.env.VUE_APP_API_HOST + '/';
  
////////////////// Generic Functions ////////////////////////
export function getData(url,params) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${Vue.prototype.$keycloak.token}`
  return axios
    .get(server_path + url,{ params })
    .then(res => res)
    .catch(error => {
      if (error.response) {
        return error.response
      } else {
        return error
      }
    })
}


export function postData(url, data,config) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${Vue.prototype.$keycloak.token}`
  return axios
    .post(server_path + url, data, config)
    .then(res => res)
    .catch(error => {
      if (error.response) {
        return error.response
      } else {
        return error
      }
    })
}

export function putData(url, id, data) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${Vue.prototype.$keycloak.token}`
  return axios
    .put(server_path + url + id + "/", data)
    .then(res => res)
    .catch(error => {
      if (error.response) {
        return error.response
      } else {
        return error
      }
    })
}

export function deleteData(url, id) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${Vue.prototype.$keycloak.token}`
  return axios
    .delete(server_path + url + id + "/")
    .then(res => res)
    .catch(error => {
      if (error.response) {
        return error.response
      } else {
        return error
      }
    })
}



