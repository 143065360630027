<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <img :src="require('@/assets/logo_dsc.svg')" alt="logo DSP Tool" style="height: 25px; width: auto; margin-right: 12px;" />
        
        <!-- Right side of Navbar -->
        <ul class="nav justify-content-end ml-auto ">
          <router-link v-if="isLoggedIn" class="nav-item nav-label me-4 " :class="{'nav-label-active': this.$route.name == 'home'}"  tag="li" to="/" exact>
            Data Viewer
          </router-link>
          <!-- <router-link v-if="isLoggedIn" class="nav-item  nav-label me-4" :class="{'nav-label-active': this.$route.name == 'download'}" tag="li" to="/" exact>
            Download
          </router-link> -->
          <li class="nav-item dropdown me-5   ms-5 nav-inicio">
            <div @click="logout" class="nav-label">
                    &nbsp;Log Out
            </div>
            <!-- <a v-if="isLoggedIn" id="navbarDropdown" class=" dropdown-toggle link-1" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <p v-if="fullname != ''" class="d-inline-block nav-user m-0 nav-label">{{fullname}}</p>
              <p v-else class="d-inline-block nav-user m-0 nav-label">{{username}}</p>
            </a> -->
            <!-- User options pop up -->
            <!-- <div class="dropdown-menu dropdown-menu-right dropdown-user" aria-labelledby="navbarDropdown">
              <div class="popup-body">
                <div>
                  <div @click="logout" class="dropdown-item pointer">
                    &nbsp;Logout
                  </div>
                </div>
              </div>
            </div> -->
          </li>
        </ul>
      </div>

    </nav>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'

export default {
  name: "vtHeader",
  components: {
  },
  data() {
    return {
    };
  },
  computed: { 
    ...mapGetters("user",{
      isLoggedIn: 'isLoggedIn',
      username: 'username',
      fullname: 'fullname',
      is_admin: 'is_admin'
    }) 
  },
  methods:{
    logout: function () {
      this.$store.dispatch('user/logout')
      .then(resp => { console.log(resp)})
      .catch(err => err)
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>

.nav-inicio {
    align-content: center;
    display: grid
}

.profile-picture{
    width: 25px;
    padding-right: 5px;
}

.dropdown-user {
  z-index: 1000000;
}



</style>
