import { postData,getData } from "@/services/api";
import { stringArray,getSelectedSensorId,commitData } from "@/services/helper";
import Vue from "vue";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import Vuelidate from "vuelidate";

Vue.use(VueToast, Vuelidate);

const state = () => ({
  cage_list: [],
  sensor_list:[],
  correntimetro_data: [],
  correntimetro_wind_rose:[],
  scl_data: [],
  scl_polar_values: {
    pitch:new Array(360).fill(0),
    roll:new Array(360).fill(0),
    heading:new Array(360).fill(0)
  },
  scl_acceleration_data: [],
  sensor_carga_data: [],
  sensor_carga_sat_data: [],
  sensor_boya_data: [],
  boya_polar_values: {
    direction:new Array(360).fill(0)
  },
  sensor_type: 'sensor-carga',
  show_sensor_carga: true,
  show_sensor_carga_sat: true,
  show_sensor_red: true,
  show_correntimetro: true,
  show_sensor_boya: true,
  sensors_last_data:[],
  sensor_selected:{
    correntimetro: 1,
    sensor_red: 43,
    sensor_carga: 1,
    sensor_carga_sat: 1,
    sensor_boya: 1,
  },
  data_filter:{
    start_date: null,
    end_date: null,
    cage: 'J101'
  },
});

const mutations = {
    set_cage_list(state, data){
        state.cage_list = data
    },
    set_sensor_list(state, data){
      state.sensor_list = data
    },
    set_sensor_type(state, data){
      state.sensor_type = data
    },
    set_correntimetro_data(state, data){
      state.correntimetro_data = data
      state.correntimetro_wind_rose = []
      var correntimetro_wind_rose_aux = []

      for (let i = 0; i <= 12; i++) {
        correntimetro_wind_rose_aux.push({
          vel_0: [],
          vel_1: [],
          vel_2: [],
          vel_3: [],
          vel_4: [],
          vel_5: []
        });
      }
      var limitsDegrees  = [];
      for (var i = 0; i < 16; i++) {
        limitsDegrees.push(i * 22.5); // Incremento de x grados
      }
      data.forEach(item => {
        for (let i = 1; i <= 12; i++) {
          var speed_index = ''
          if (item['speed_'+i] < 0.1)
            speed_index = '_0'
          else if (item['speed_'+i] >= 0.1 && item['speed_'+i]  < 0.15)
            speed_index = '_1'
          else if (item['speed_'+i] >= 0.15 && item['speed_'+i]  < 0.2)
            speed_index = '_2'
          else if (item['speed_'+i] >= 0.2 && item['speed_'+i]  < 0.25)
            speed_index = '_3'
          else if (item['speed_'+i] >= 0.25 && item['speed_'+i]  < 0.3)
            speed_index = '_4'
          else if (item['speed_'+i] >= 0.3)
            speed_index = '_5'

          var value_in = {
            'dir': item['dir_'+i],
            'speed': item['speed_'+i]
          }
          correntimetro_wind_rose_aux[i - 1]['vel'+ speed_index].push(value_in)
        }
      })
      correntimetro_wind_rose_aux.forEach(depths => {
        var r_aux = [[],[],[],[],[],[]]
        for (let i = 0; i <= limitsDegrees.length - 1; i++) {
          var degreeMin = limitsDegrees[i];
          var degreeMax = limitsDegrees[i + 1]
          if (!degreeMax)
            degreeMax = 360
          for (let j = 1; j <= 6; j++) {
            //vel_sum = sum(depths['vel_'+ (j - 1)])
            var filter_degree = depths['vel_'+ (j - 1)].filter((data,index) => {
              return data['dir'] >= degreeMin && data['dir'] < degreeMax;
            });
            var averageSpeeds = 0
            if (filter_degree.length > 0) {
              var sumSpeeds = filter_degree.reduce((accumulator, data) => {
                return accumulator + data['speed'];
              }, 0);
              averageSpeeds = sumSpeeds / filter_degree.length;
              //averageSpeeds = (sumSpeeds / depths['vel_'+ (j - 1)].length) * 100;
            }
            r_aux[j - 1].push(averageSpeeds.toFixed(3))
            //r_aux[j - 1].push(filter_degree.length)
          }
        }
        state.correntimetro_wind_rose.push(r_aux)
      });
    },
    set_scl_data(state, data){
        state.scl_data = data
        
        var scl_polar_pitch = state.scl_polar_values.pitch
        var scl_polar_roll = state.scl_polar_values.roll
        var scl_polar_heading = state.scl_polar_values.heading
        data.forEach(item => {
          var pitch_index = Math.floor((item.pitch < 0 ? item.pitch + 360 : item.pitch)/1);
          var roll_index = Math.floor((item.roll < 0 ? item.roll + 360 : item.roll)/1);
          var heading_index = Math.floor((item.heading)/1);
          scl_polar_pitch[pitch_index]++ 
          scl_polar_roll[roll_index]++ 
          scl_polar_heading[heading_index]++ 
        });  
        state.scl_polar_values.pitch = scl_polar_pitch
        state.scl_polar_values.roll = scl_polar_roll
        state.scl_polar_values.heading = scl_polar_heading
    },
    set_acceleration_data(state, data){
        state.scl_acceleration_data = data
    },
    set_sensor_carga(state, data){
      state.sensor_carga_data = data
    },
    set_sensor_carga_sat(state, data){
      state.sensor_carga_sat_data = data
    },
    set_sensor_boya(state, data){
      state.sensor_boya_data = data
      var boya_polar_direction = state.boya_polar_values.direction
      data.forEach(item => {
        var direction_index = Math.floor((item.direction)/1);
        boya_polar_direction[direction_index]++ 
      });  
      state.boya_polar_values.direction = boya_polar_direction
    },
    set_data_filter(state, data) {
        state.data_filter[data[0]] = data[1];
    },
    set_sensor_selected(state, data) {
      state.sensor_selected[data[0]] = data[1];
    },
    set_show_sensor_carga(state, value){
      state.show_sensor_carga = value
    },
    set_show_sensor_carga_sat(state, value){
      state.show_sensor_carga_sat = value
    },
    set_show_sensor_red(state, value){
      state.show_sensor_red = value
    },
    set_show_correntimetro(state, value){
      state.show_correntimetro = value
    },
    set_show_sensor_boya(state, value){
      state.show_sensor_boya = value
    },
    set_sensors_last_data(state, values){
      state.sensors_last_data = values
    },
};

const actions = {
  change_sensor_type({ commit }, data) {
    commit("set_sensor_type", data);
  },
  change_data_filter({ commit }, data) {
    commit("set_data_filter", data);
  },
  change_sensor_selected({ commit }, data) {
    commit("set_sensor_selected", data);
  },
  change_show_sensor_carga({ commit }, value) {
    commit("set_show_sensor_carga", value);
  },
  change_show_sensor_carga_sat({ commit }, value) {
    commit("set_show_sensor_carga_sat", value);
  },
  change_show_sensor_red({ commit }, value) {
    commit("set_show_sensor_red", value);
  },
  change_show_correntimetro({ commit }, value) {
    commit("set_show_correntimetro", value);
  },
  change_show_sensor_boya({ commit }, value) {
    commit("set_show_sensor_boya", value);
  },
  async get_sensors_last_data({ commit, dispatch, getters }) {
    var res = await getData('acquisition/last-day-data/')
    if (res.status == "200") { 
      commit("set_sensors_last_data", res.data.results)
    }else{
      Vue.$toast.open({
        message: "An unexpected error has occurred: " + res.data,
        type: "error",
        position: "top",
        pauseOnHover: true,
        duration: 6000,
      })
    }

  },
  async get_sensor_data({ commit, dispatch, getters },payload) {
    // Set 'loading' status
    dispatch("ui/change_loading_data", 1,{ root: true })

    var sensor_id = getSelectedSensorId(getters)
    var sensor_type = getters['sensor_type']
    var sensor_type_url = ''

    if (payload){
      sensor_type = payload.sensor_type
      sensor_id = payload.sensor_id
    }

    if (sensor_type == 'sensor-carga' || sensor_type == 'scl')
      sensor_type_url = sensor_type + '-avg'
    else
      sensor_type_url = sensor_type

    dispatch('change_data_filter', ['sensor_id',sensor_id])
    var res = await getData('acquisition/'+ sensor_type_url + '/', getters['data_filter'])

    if (res.status == "200") {  
        var results = res.data.results
        while (res.data.next){
          var next = res.data.next.split('/')
          var next_params = next[next.length -1]
          res = await getData('acquisition/'+ sensor_type_url + '/' + next_params , null)
          results = [...results, ...res.data.results] 
        }
        commitData(commit,sensor_type,results)
        dispatch("ui/change_loading_data", 2, { root: true })
      return 'ok'
    } else if (res.status < "500") {
      Vue.$toast.open({
        message: "An unexpected error has occurred: " + res.data,
        type: "error",
        position: "top",
        pauseOnHover: true,
        duration: 6000,
      })
      dispatch("ui/change_loading_data", 0, { root: true })

      return 'error'
    }else{
      Vue.$toast.open({
        message: "The server is out of order.",
        type: "error",
        position: "top",
        pauseOnHover: true,
        duration: 6000,
      })
      dispatch("ui/change_loading_data", 0,{ root: true })
      return 'error'
    }
  },
  async get_sensor_info({ commit, dispatch, getters }) {
    var res = await getData('acquisition/sensor-info/', null)
    if (res.status == "200") { 
      res.data.results.map(obj => obj.date_time);
      const cages = [...new Set(res.data.results.map(obj => obj.cage))];

      const DifferentCombinations = res.data.results.reduce((combinations, obj) => {
        const { sensor_id, sensor_type,depth } = obj;
        const clave = `${sensor_id}_${sensor_type}`;
        combinations[clave] = { sensor_id, sensor_type,depth };
        return combinations;
      }, {});
      const sensors_list = Object.values(DifferentCombinations);
      commit("set_cage_list", cages)
      commit("set_sensor_list", sensors_list)
    }else {
      Vue.$toast.open({
        message: "An unexpected error has occurred: " + res.data,
        type: "error",
        position: "top",
        pauseOnHover: true,
        duration: 6000,
      })
      console.log(res)
      return 'error'
    }
  },
  async export_data_csv({ commit, dispatch, getters }) {
    var data_filter = getters['data_filter']
    var sensor_id = getSelectedSensorId(getters)
    data_filter['sensor_id'] = sensor_id
    data_filter['sensor_type'] = getters['sensor_type'].replace(/-/g, "_")
    if (getters['start_date'] == "") getters['start_date'] = null
    if (getters['end_date'] == "") getters['end_date'] = null

    dispatch("ui/change_loading_data", 1 ,{ root: true })
    var res = await postData('acquisition/export-data/', data_filter,{ responseType: 'blob' })
    if (res.status == "200") { 
      let blob = new Blob([res.data], { type: 'application/zip' })
      let fileName = res.headers["content-disposition"].split(
        "filename="
      )[1];
      fileName = fileName.slice(1, -1);
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = fileName
      link.click()
     dispatch("ui/change_loading_data", 2,{ root: true })
      
    }else {
      Vue.$toast.open({
        message: "Export error",
        type: "error",
        position: "top",
        pauseOnHover: true,
        duration: 6000,
      })
      console.log(res)
      dispatch("ui/change_loading_data", 0,{ root: true })
      return 'error'
    }
  }
};

const getters = {
    sensor_type: state => state.sensor_type,
    correntimetro_data: state => state.correntimetro_data,
    correntimetro_wind_rose: state => state.correntimetro_wind_rose,
    scl_data: state => state.scl_data,
    scl_polar_values: state => state.scl_polar_values,
    scl_acceleration_data: state => state.scl_acceleration_data,
    sensor_carga_data: state => state.sensor_carga_data,
    sensor_carga_sat_data: state => state.sensor_carga_sat_data,
    sensor_boya_data: state => state.sensor_boya_data,
    boya_polar_values: state => state.boya_polar_values,
    data_filter: state => state.data_filter,
    cage_list: state => state.cage_list,
    sensor_list: state => state.sensor_list,
    sensor_list_filter: (state) => (sensor_type) => {
      var sensor_list = [...state.sensor_list]
      sensor_list = sensor_list.filter(function(sensor) {
        if(sensor.sensor_type == sensor_type)
          return true
      })
      return [...sensor_list]
    },
    sensor_depth_filter: (state) => (sensor_type,sensor_id) => {
      var sensor_list = [...state.sensor_list]
      sensor_list = sensor_list.filter(function(sensor) {
        if(sensor.sensor_type == sensor_type && sensor.sensor_id == sensor_id)
          return true
      })
      if (sensor_list.length > 0)
        return sensor_list[0].depth
      else
        return 0
    },
    sensors_last_data_filter: (state) => (sensor_model,sensor_id) => {
      var sensors_last_data = [...state.sensors_last_data]
      sensors_last_data = sensors_last_data.filter(function(sensor) {
        if(sensor.sensor_model == sensor_model && sensor.sensor_id == sensor_id)
          return true
      })
      if (sensors_last_data.length == 0)
        return 'N/A'
      
      var last_date = new Date(sensors_last_data[0].last_day)
      var current_date = new Date();
      var difference_milliseconds = current_date - last_date
      var past_days =  Math.floor(difference_milliseconds / (1000 * 60 * 60 * 24));
      return past_days
    },
    sensor_selected: state => state.sensor_selected,
    show_sensor_carga: state => state.show_sensor_carga,
    show_sensor_carga_sat: state => state.show_sensor_carga_sat,
    show_sensor_red: state => state.show_sensor_red,
    show_correntimetro: state => state.show_correntimetro,
    show_sensor_boya: state => state.show_sensor_boya,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  VueToast,
  Vuelidate,
};
