<template>
    <div class="panel-container">
      <div class="panel-block">
        <div class="form-group panel-section mb-4">
          <div class="row">
            <div class="col-12 mt-1">
              <label class="panel-label-primary">CAGE</label>
            </div>
            <div class="col-12">
              <select class="from-control-slim "  v-model=cage>
                <option v-for="item in cage_list" :value=item  :key="item" >Cage {{item}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group panel-section">
          <div class="row col-12 mt-1">
            <label class="panel-label-primary">SENSORS</label>
          </div>
          <div class="panel-sub-section panel-sub-section-top">
            <div>
              <div class="form-check form-switch ps-0">
                <img :src="require('@/assets/icons/icon_sensor_load_1.svg')" alt="icon sensor load" class="sub-section-icon" />
                <label class="sub-section-label d-inline ">Load Sensors (Sat)</label>
                <input class="form-check-input form-check-sub-section" type="checkbox" role="switch" v-model=show_sensor_carga_sat >
              </div>
            </div>
            <select class="from-control-slim mb-2" v-model=selected_sensor_carga_sat>
              <option v-for="item in sensor_list_filter('sensor_carga_sat')" :value=item.sensor_id  :key="item.sensor_id" >ID {{item.sensor_id}}</option>
            </select>
            <span class="sub-section-spam">Last update {{ sensors_last_data_filter('sensor_carga_sat',selected_sensor_carga_sat) }} days ago</span>
          </div>
          <div class="panel-sub-section panel-sub-section-middle mt-1">
            <div>
              <div class="form-check form-switch ps-0">
                <img :src="require('@/assets/icons/icon_sensor_load_1.svg')" alt="icon sensor load" class="sub-section-icon" />
                <label class="sub-section-label d-inline ">Load Sensors</label>
                <input class="form-check-input form-check-sub-section" type="checkbox" role="switch" v-model=show_sensor_carga >
              </div>
            </div>
            <select class="from-control-slim mb-2" v-model=selected_sensor_carga>
              <option v-for="item in sensor_list_filter('sensor_carga')" :value=item.sensor_id  :key="item.sensor_id" >ID {{item.sensor_id}}</option>
            </select>
            <span class="sub-section-spam">Last update {{ sensors_last_data_filter('sensor_carga',selected_sensor_carga) }} days ago</span>
          </div>
          <div class="panel-sub-section panel-sub-section-middle mt-1">
            <div>
              <div class="form-check form-switch ps-0">
                <img :src="require('@/assets/icons/icon_sensor_net_1.svg')" alt="icon sensor net" class="sub-section-icon" />
                <label class="sub-section-label d-inline ">Net / Floatability Sensors</label>
                <input class="form-check-input form-check-sub-section" type="checkbox" role="switch" v-model=show_sensor_red >
              </div>
            </div>
            <select class="from-control-slim mb-2" v-model=selected_sensor_red>
              <option v-for="item in sensor_list_filter('sensor_red')" :value=item.sensor_id  :key="item.sensor_id" >ID {{item.sensor_id}}</option>
            </select>
            <span class="sub-section-spam">Last update {{ sensors_last_data_filter('scl',selected_sensor_red) }} days ago</span>
          </div>
          <div class="panel-sub-section panel-sub-section-middle mt-1">
            <div>
              <div class="form-check form-switch ps-0">
                <img :src="require('@/assets/icons/icon_sensor_current_1.svg')" alt="icon currentmeter" class="sub-section-icon" />
                <label class="sub-section-label d-inline ">Currentmeter</label>
                <input class="form-check-input form-check-sub-section" type="checkbox" role="switch" v-model=show_correntimetro >
              </div>
            </div>
            <select class="from-control-slim mb-2" v-model=selected_correntimetro>
              <option v-for="item in sensor_list_filter('correntimetro')" :value=item.sensor_id  :key="item.sensor_id" >ID {{item.sensor_id}}</option>
            </select>
            <span class="sub-section-spam">Last update {{ sensors_last_data_filter('correntimetro',selected_correntimetro) }} days ago</span>
          </div>
          <div class="panel-sub-section panel-sub-section-bottom mt-1">
            <div>
              <div class="form-check form-switch ps-0">
                <img :src="require('@/assets/icons/icon_sensor_current_1.svg')" alt="icon currentmeter" class="sub-section-icon" />
                <label class="sub-section-label d-inline ">Boya</label>
                <input class="form-check-input form-check-sub-section" type="checkbox" role="switch" v-model=show_sensor_boya >
              </div>
            </div>
            <select class="from-control-slim mb-2" v-model=selected_sensor_boya>
              <option v-for="item in sensor_list_filter('sensor_boya')" :value=item.sensor_id  :key="item.sensor_id" >ID {{item.sensor_id}}</option>
            </select>
            <span class="sub-section-spam">Last update {{ sensors_last_data_filter('sensor_boya',selected_sensor_boya) }} days ago</span>
          </div>
        </div>

        <div class="form-group panel-section mt-4">
          <div class="row col-12 mt-1">
            <label class="panel-label-primary">Filter</label>
          </div>
          <div class="panel-sub-section panel-sub-section-top  mb-2 pb-4">
            <div class="form-group ">
              <label  class="filter-label" for="sensor_type">Sensor Type</label>
              <select class="form-control"  v-model=sensor_type>
                <option value="correntimetro">Currentmeter</option>
                <option value="scl">Net sensor</option>
                <option value="sensor-carga">Load sensor</option>
                <option value="sensor-carga-sat">Load sensor (sat)</option>
                <option value="sensor-boya">Boya sensor</option>
              </select>
            </div>
            <div class="form-group ">
              <label  class="filter-label">Date init</label>
              <input type="datetime-local" class=" form-control" v-model=start_date aria-describedby="date_init" placeholder="Date init" >
            </div>
            <div class="form-group">
              <label  class="filter-label" >Date fin</label>
              <input type="datetime-local" class="form-control" v-model=end_date aria-describedby="date_fin" placeholder="Date fin" >
            </div>
          </div>
          <div class=" panel-sub-section panel-sub-section-bottom pb-4 pt-4">
            <div class="col-12 parent">
              <vue-element-loading :active="loading_data == 1" spinner="bar-fade-scale"  text="Please wait..." background-color="#388891" color="#FFF"/>
              <button  v-on:click = getSensorData() class="btn btn-light">Filter data</button>
              <button  v-on:click = exportDataCSV() class="float-end btn btn-light">Export data</button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { Plotly } from 'vue-plotly';
import { mapGetters } from "vuex";
import VueElementLoading from "vue-element-loading";
export default {
  name: 'panel',
  components: {
    Plotly,
    VueElementLoading
  },
  data () {
    return {
      show_1: true,
      show_2: false
    }
  },
  created: function() { 
    
  },
  computed: {
    ...mapGetters({
      cage_list: 'sensors/cage_list',
      sensor_list: 'sensors/sensor_list',
      loading_data: 'ui/loading_data',
      sensor_list_filter: 'sensors/sensor_list_filter',
      sensors_last_data_filter: 'sensors/sensors_last_data_filter'
    }), 
    sensor_type: {
        set (value) { this.$store.dispatch('sensors/change_sensor_type', value) },
        get () { return this.$store.state.sensors.sensor_type }
    },
    start_date: {
        set (value) { this.$store.dispatch('sensors/change_data_filter', ['start_date',value]) },
        get () { return this.$store.state.sensors.data_filter.start_date }
    },
    end_date: {
        set (value) { this.$store.dispatch('sensors/change_data_filter', ['end_date',value]) },
        get () { return this.$store.state.sensors.data_filter.end_date }
    },
    cage: {
        set (value) { this.$store.dispatch('sensors/change_data_filter', ['cage',value]) },
        get () { return this.$store.state.sensors.data_filter.cage }
    },
    selected_correntimetro: {
        set (value) { this.$store.dispatch('sensors/change_sensor_selected', ['correntimetro',value]) },
        get () { return this.$store.state.sensors.sensor_selected.correntimetro }
    },
    selected_sensor_red: {
        set (value) { this.$store.dispatch('sensors/change_sensor_selected', ['sensor_red',value]) },
        get () { return this.$store.state.sensors.sensor_selected.sensor_red }
    },
    selected_sensor_carga: {
        set (value) { this.$store.dispatch('sensors/change_sensor_selected', ['sensor_carga',value]) },
        get () { return this.$store.state.sensors.sensor_selected.sensor_carga }
    },
    selected_sensor_carga_sat: {
        set (value) { this.$store.dispatch('sensors/change_sensor_selected', ['sensor_carga_sat',value]) },
        get () { return this.$store.state.sensors.sensor_selected.sensor_carga_sat }
    },
    selected_sensor_boya: {
        set (value) { this.$store.dispatch('sensors/change_sensor_selected', ['sensor_boya',value]) },
        get () { return this.$store.state.sensors.sensor_selected.sensor_boya }
    },
    show_sensor_carga: {
        set (value) { this.$store.dispatch('sensors/change_show_sensor_carga', value) },
        get () { return this.$store.state.sensors.show_sensor_carga }
    },
    show_sensor_carga_sat: {
        set (value) { this.$store.dispatch('sensors/change_show_sensor_carga_sat', value) },
        get () { return this.$store.state.sensors.show_sensor_carga_sat }
    },
    show_sensor_red: {
        set (value) { this.$store.dispatch('sensors/change_show_sensor_red', value) },
        get () { return this.$store.state.sensors.show_sensor_red }
    },
    show_correntimetro: {
        set (value) { this.$store.dispatch('sensors/change_show_correntimetro', value) },
        get () { return this.$store.state.sensors.show_correntimetro }
    },
    show_sensor_boya: {
        set (value) { this.$store.dispatch('sensors/change_show_sensor_boya', value) },
        get () { return this.$store.state.sensors.show_sensor_boya }
    }, 
  },
  methods: {
    getSensorData: async function () {
      await this.$store.dispatch('sensors/get_sensor_data',null)
    },
    exportDataCSV: async function () {
      await this.$store.dispatch('sensors/export_data_csv')
    },
   }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>
  .sub-section-icon{
    height: 15px; 
    width: auto; 
    margin-right: 5px;
  }
</style>