<template>
  <div class="container-fluid p-0 home-bg">
    <div class="row home-container">
      <div class="col-6 col-sm-5 col-md-4 col-lg-3 col-xl-3 p-0 h-100">
        <panel></panel>
      </div>
      <div class="col-6 col-sm-7 col-md-8 col-lg-9 col-xl-9 p-0 h-100 pr-2" style="overflow: auto;">
        <dataView></dataView>
      </div>
    </div>
  </div>
</template>

<script>

import panel from '@/components/panel'
import dataView from '@/components/dataView'
import { mapGetters } from "vuex";

export default {
  name: 'Home',
  components: {
    panel,
    dataView
  },
  computed: {
    ...mapGetters({
        sensor_selected: 'sensors/sensor_selected',
    }), 
  },
  data() {
    return {
  
    }
  },
  async created() {
    await this.$store.dispatch('sensors/get_sensor_info')
    await this.$store.dispatch('sensors/get_sensors_last_data')
    await this.$store.dispatch('sensors/get_sensor_data', {sensor_type: 'sensor-carga-sat', sensor_id: this.sensor_selected.sensor_carga_sat })
    await this.$store.dispatch('sensors/get_sensor_data', {sensor_type: 'sensor-carga', sensor_id: this.sensor_selected.sensor_carga })
    await this.$store.dispatch('sensors/get_sensor_data', {sensor_type: 'scl', sensor_id: this.sensor_selected.sensor_red })
    await this.$store.dispatch('sensors/get_sensor_data', {sensor_type: 'correntimetro', sensor_id: this.sensor_selected.correntimetro })
    await this.$store.dispatch('sensors/get_sensor_data', {sensor_type: 'sensor-boya', sensor_id: this.sensor_selected.sensor_boya })

  },
  mounted () {
  },
  watch: {
  },
  methods: {
 
  }
}
</script>
<style scoped>
</style>