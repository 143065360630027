import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    // meta: {
    //   requiresAuth: true
    // }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// router.beforeEach((to, from, next) => {
//   if(to.matched.some(record => record.meta.requiresNotAuth)) {
//     if (!store.getters['user/isLoggedIn']) {
//       next()
//       return
//     }
//     next('/')
//   } else {
//     next()
//   }
// })

// router.beforeEach((to, from, next) => {
//   if(to.matched.some(record => record.meta.requiresAuth)) {
//     if (store.getters['user/isLoggedIn']) {
//       next()
//       return
//     }
//   } else {
//     next()
//   }
// })


export default router
