const state = () => ({
  status: 1, // 1.- Normal / 2.- Loading / 3.- Waiting for response / 4.- Error
  loading_data: 0 // 0.- No data / 1.- Loading / 2.- data
})

const mutations = {
  set_status(state, data){
    state.status = data
  },
  set_loading_data(state, data){
    state.loading_data = data
  },
  
}

const actions = {
  change_status({ commit }, status) {
    commit("set_status", status)
  },
  change_loading_data({ commit }, loading_data) {
    commit("set_loading_data", loading_data)
  }
}

 const getters = {
    status: state => state.status,
    loading_data: state => state.loading_data
  }

  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }