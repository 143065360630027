import axios from 'axios'
import * as localData from '@/services/localData.js'
import Vue from 'vue'


const state = () => ({
  status: '',
  auth_token: localData.getlocal('auth_token') || '',
  refresh_token:localData.getlocal('refresh_token') || '',
  username : localData.getlocal('username') || '',
  fullname: localData.getlocal('fullname') || '',
  user_id: localData.getlocal('user_id') || '',
  email: localData.getlocal('email') || '',
  roles: localData.getArraylocal("roles") || [],
})

const mutations = {
  auth_request(state){
    state.status = 'loading'
  },
  auth_success(state, data){
    state.status = "success";
    state.auth_token = data.token;
    state.refresh_token = data.refreshToken;
    state.username = data.userName;
    state.fullname = data.fullName;
    state.user_id = data.tokenParsed.sub;
    state.email = data.tokenParsed.email;
    state.roles = data.realmAccess.roles;
  },
  auth_error(state){
    state.status = 'error'
  },
  logout(state){
    state.status = "";
    state.auth_token = "";
    state.refresh_token = "";
    state.username = "";
    state.fullname = "";
    state.user_id = "";
    state.email = "";
    state.roles = "";
  },
  auth_validate(state, auth_token){
    state.status = 'success'
    state.auth_token = auth_token
  }
}

const actions = {
  setUserParams({ commit },user) {
    return new Promise((resolve, reject) => {    
      //console.log(user)
      //localData.setlocalToken("auth_token", user.token);
      //localData.setlocal("refresh_token", user.refreshToken);

      if (localStorage.username != user.userName){
        localData.setlocal("username", user.userName);
        localData.setlocal("fullname", user.fullName);
        localData.setlocal("user_id", user.tokenParsed.sub);
        localData.setlocal("email", user.tokenParsed.email);
        localData.setlocal("roles", user.realmAccess.roles);

        commit("auth_success", user);
      }
    });
  },
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$keycloak.logoutFn()
      commit("logout");
      localData.deleteAll();
      Vue.noty.success("Goodbye")
      resolve("OK");
    });
  }
}

 const getters = {
    isLoggedIn: state => !!state.user_id,
    authStatus: state => state.status,
    token: state => state.auth_token,
    username: state => state.username,
    user_id: state => state.user_id,
    is_admin: state => state.is_admin,
    fullname: state => state.fullname,
    email: state => state.email,
  }

  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }