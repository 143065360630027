<template>
    <div class="general-container container-fluid mb-3 ">
      <div v-if="show_sensor_carga_sat">
        <div class="row mt-3 mb-2 ps-3">
          <div class="col-auto">
            <h3 class="panel-label-primary pt-2">Load sensor (sat)</h3>
          </div>
        </div>
        <div class="row mt-3 ml-5 ps-3">
          <div class="col-3">
            <div class="form-inline">
              <label class="mr-3 ml-3" for="param" >Param: </label>
              <select class="form-control"  v-model=sensor_carga_sat_select_param>
                <option value="average_load">Average load</option>
                <option value="max_load">Max load</option>
                <option value="min_load">Min load</option>
              </select>
            </div>
          </div>
        </div>
        <div class="container-flow row me-4">
          <Plotly :data="sensor_carga_sat_data_graph" :layout="layout_sensor_carga_sat" :scrollZoom="true" :displaylogo="false" :modeBarButtonsToRemove="['resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian']"></Plotly>
        </div>
      </div>
      <div v-if="show_sensor_carga">
        <div class="row mt-3 mb-2 ps-3">
          <div class="col-auto">
            <h3 class="panel-label-primary pt-2">Load sensor</h3>
          </div>
        </div>
        <div class="container-flow row me-4">
          <Plotly :data="sensor_carga_data_graph" :layout="layout_sensor_carga" :scrollZoom="true" :displaylogo="false" :modeBarButtonsToRemove="['resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian']"></Plotly>
        </div>
      </div>
      <div v-if="show_sensor_red">
        <div class="row mt-3 mb-2 ps-3">
          <div class="col-auto">
            <h3 class="panel-label-primary pt-2">Net / Floatability sensor</h3>
          </div>
        </div>
        <div class="row mt-3 ml-5 ps-3">
          <div class="col-3">
            <div class="form-inline">
              <label class="mr-3 ml-3" for="param" >Param: </label>
              <select class="form-control"  v-model=scl_select_param>
                <!-- <option value="temperature">temperature</option> -->
                <option value="depth">depth</option>
                <option value="pitch">pitch</option>
                <option value="roll">roll</option>
                <option value="heading">heading</option>
                <!-- <option value="four_p">four_p</option> -->
              </select>
            </div>
          </div>
        </div>
        <div class="container-flow row me-3">
          <div class="col-md-12 pe-0" :class="{'col-lg-8': scl_select_param == 'pitch' || scl_select_param == 'roll' || scl_select_param == 'heading'}">
            <Plotly :data="scl_data_graph" :layout="layout_scl" :scrollZoom="true" :displaylogo="false" :modeBarButtonsToRemove="['resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian']"></Plotly>
          </div>
          <div class="col-md-12 col-lg-4 ps-0" v-if="scl_select_param != 'depth'">
            <Plotly :data="scl_polar_graph" :layout="layout_polar_scl" :scrollZoom="true" :displaylogo="false" :modeBarButtonsToRemove="['resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian']"></Plotly>
          </div>
        </div>
        
      </div>
      <!-- <div class="row mt-3 mb-2">
        <div class="col-auto">
          <h3 class="panel-label-primary pt-2">Net sensor acceleration</h3>
        </div>
      </div>
      <div class="row  mt-3 ml-5">
        <div class="col-3">
          <div class="form-inline">
            <label class="mr-3 ml-3" for="param">Param: </label>
            <select class="form-control"  v-model=scl_acceleration_select_param>
              <option value="G">G</option>
              <option value="Xacc">Xacc</option>
              <option value="Yacc">Yacc</option>
              <option value="Zacc">Zacc</option>
            </select>
          </div>
        </div>
      </div>
      <Plotly :data="scl_acceleration_data_graph" :layout="layout_scl_acceleration" :scrollZoom="true" :displaylogo="false" :modeBarButtonsToRemove="['resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian']"></Plotly>
    -->
      <div v-if="show_correntimetro">
        <div class="row mt-3 mb-2 ps-3">
          <div class="col-auto">
            <h3 class="panel-label-primary pt-2">Currentmeter</h3>
          </div>
        </div>
        <div class="row">
            <div class="col-12 text-center">
              <h3>Speed</h3>
            </div>
        </div>
        <div class="container-flow row me-3">
          <Plotly :data="correntimetro_speed_data_graph" :layout="getLayout('Speed')" :scrollZoom="true" :displaylogo="false" :modeBarButtonsToRemove="['resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian']"></Plotly>
        </div>
        <div class="row">
            <div class="col-12 text-center">
              <h3 class="mb-0">Direction</h3>
            </div>
        </div>
        <div class="container-flow row me-3">
          <Plotly class="mt-3" :data="correntimetro_dir_data_graph" :layout="getLayout('Dir')" :scrollZoom="true" :displaylogo="false" :modeBarButtonsToRemove="['resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian']"></Plotly>
        </div>
        <div class="row mt-3 ml-5 ps-3">
          <div class="col-3">
            <div class="form-inline">
              <label class="mr-3 ml-3" for="param" >Depth: </label>
              <select v-if="selected_correntimetro" class="form-control"  v-model=correntimetro_select_depth>
                <option v-for="(depth,index) in correntimetro_depths_list" :key="index" :value="index" >depth {{sensor_depth_filter('correntimetro',selected_correntimetro) - depth}} m</option>
              </select>
            </div>
          </div>
        </div>
        <div class="container-flow row me-3">
          <Plotly class="mt-3" :data="correntimetro_wind_rose_data_graph" :layout="layout_wind_rose_correntimetro" :scrollZoom="true" :displaylogo="false" :modeBarButtonsToRemove="['resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian']"></Plotly>
        </div>
      </div>
      <div v-if="show_sensor_boya">
        <div class="row mt-3 mb-2 ps-3">
          <div class="col-auto">
            <h3 class="panel-label-primary pt-2">Boya sensor</h3>
          </div>
        </div>
        <div class="row mt-3 ml-5 ps-3">
          <div class="col-3">
            <div class="form-inline">
              <label class="mr-3 ml-3" for="param" >Param: </label>
              <select class="form-control"  v-model=sensor_boya_select_param>
                <option value="significant_waveheight">significant waveheight</option>
                <option value="t1">Middle period</option>
                <option value="direction">direction</option>
              </select>
            </div>
          </div>
        </div>
        <div class="container-flow row me-3">
          <div class="col-md-12 pe-0" :class="{'col-lg-8': sensor_boya_select_param == 'direction'}">
            <Plotly :data="sensor_boya_data_graph" :layout="layout_sensor_boya" :scrollZoom="true" :displaylogo="false" :modeBarButtonsToRemove="['resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian']"></Plotly>
          </div>
          <div class="col-md-12 col-lg-4 ps-0"  v-if="sensor_boya_select_param == 'direction'">
            <Plotly :data="boya_polar_graph" :layout="layout_polar_boya" :scrollZoom="true" :displaylogo="false" :modeBarButtonsToRemove="['resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian']"></Plotly>
          </div>
        </div>
      </div>
   </div>
</template>

<script>
import { mapGetters } from "vuex";
import { stringArray,csvmaker,asignarColor } from "@/services/helper";
import { Plotly } from 'vue-plotly'

export default {
  name: 'DataView',
  components: {
    Plotly
  },
  data () {
    return {
      scl_select_param: 'pitch',
      scl_acceleration_select_param: 'G',
      sensor_carga_select_param: 'weight',
      sensor_carga_sat_select_param: 'average_load',
      sensor_boya_select_param: 'significant_waveheight',
      correntimetro_select_depth: 0,
      correntimetro_depths_list: [2.5,4.5,6.5,8.5,10.5,12.5,14.5,16.5,18.5,20.5,22.5,24.5],
      correntimetro_speed_data_graph: [],
      correntimetro_dir_data_graph: [],
      correntimetro_wind_rose_data_graph: [
        {
          r: [],
          theta: ["North", "N-E", "East", "S-E", "South", "S-W", "West", "N-W"],
          name: "> 0.3 m/s",
          marker: {color: "#440154"}, // fde725
          type: "barpolar",
        },
        {
          r: [],
          theta: ["North", "N-E", "East", "S-E", "South", "S-W", "West", "N-W"],
          name: "0.25-0.3 m/s",
          marker: {color: "#414487"}, //7ad151
          type: "barpolar"
        },
        {
          r: [],
          theta: ["North", "N-E", "East", "S-E", "South", "S-W", "West", "N-W"],
          name: "0.2-0.25 m/s",
          marker: {color: "#2a788e"}, // 22a884
          type: "barpolar"
        },
        {
          r: [],
          theta: ["North", "N-E", "East", "S-E", "South", "S-W", "West", "N-W"],
          name: "0.15-0.2 m/s",
          marker: {color: "#22a884"}, // 2a788e
          type: "barpolar"
        },
        {
          r: [],
          theta: ["North", "N-E", "East", "S-E", "South", "S-W", "West", "N-W"],
          name: "0.1-1.5 m/s",
          marker: {color: "#7ad151"}, //414487
          type: "barpolar"
        },
        {
          r: [],
          theta: ["North", "N-E", "East", "S-E", "South", "S-W", "West", "N-W"],
          name: "0-0.1 m/s",
          marker: {color: "#fde725"}, //440154
          type: "barpolar"
        }
      ],
      scl_polar_graph: [
        {
          r: [], // (datosAngulos) Número de veces que se repite cada rango
          theta: [], // (angulos) Ángulos en grados
          type: 'scatterpolar',
          fill: 'toself',
          name: 'Frequency Ranges' 
        }
      ],
      scl_data_graph: [
        {
          x: [],
          y: [],
          name: 'scl',
          mode: 'lines',
          //hoverinfo: 'skip',
          line: {
            color: 'rgba(55, 210, 242, 0.7)',
            width: 1
          },
        }
      ],
      scl_acceleration_data_graph: [
        {
          x: [],
          y: [],
          name: 'scl acceleration',
          mode: 'lines',
          //hoverinfo: 'skip',
          line: {
            color: 'rgba(55, 210, 242, 0.7)',
            width: 1
          },
        }
      ],
      sensor_carga_data_graph: [
        {
          x: [],
          y: [],
          name: 'Load',
          type: 'bar',
          marker: {
          },
          //hoverinfo: 'skip',
          line: {
            color: 'rgba(55, 210, 242, 0.7)',
            width: 1
          },
        }
      ],
      sensor_carga_sat_data_graph: [
        {
          x: [],
          y: [],
          name: 'Load',
          type: 'bar',
          marker: {
          },
          //hoverinfo: 'skip',
          line: {
            color: 'rgba(55, 210, 242, 0.7)',
            width: 1
          },
        }
      ],
      sensor_boya_data_graph: [
        {
          x: [],
          y: [],
          name: 'Boya',
          type: 'lines',
          marker: {
          },
          //hoverinfo: 'skip',
          line: {
            color: 'rgba(55, 210, 242, 0.7)',
            width: 1
          },
        }
      ],
      boya_polar_graph: [
        {
          r: [], // (datosAngulos) Número de veces que se repite cada rango
          theta: [], // (angulos) Ángulos en grados
          type: 'scatterpolar',
          fill: 'toself',
          name: 'Frequency Ranges' 
        }
      ],
      layout_scl:{},
      layout_scl_acceleration:{},
      layout_sensor_carga:{},
      layout_sensor_carga_sat:{},
      layout_sensor_boya:{},
      layout_polar_scl:{},
      layout_polar_boya:{},
      layout_wind_rose_correntimetro: {
        // title: "Wind Speed Distribution in Laurel, NE",
        font: {size: 16},
        legend: {font: {size: 16}},
        polar: {
          barmode: "overlay",
          bargap: 3,
          radialaxis: {ticksuffix: "m/s", angle: 45,color:'#094293',}, //eca053 || 308446
          angularaxis: {direction: "clockwise"},
          
        },
        height: 700,
        paper_bgcolor: '#f7f7f7'
      },
    }
  },
  created: function() { 
    this.layout_scl = this.getLayout(this.scl_select_param)
    //this.layout_scl_acceleration = this.getLayout(this.scl_acceleration_select_param)
    this.layout_sensor_carga = this.getLayout(this.sensor_carga_select_param)
    this.layout_sensor_carga_sat = this.getLayout(this.sensor_carga_sat_select_param)
  },
  computed: {
    ...mapGetters({
        correntimetro_data: 'sensors/correntimetro_data',
        correntimetro_wind_rose: 'sensors/correntimetro_wind_rose',
        scl_data: 'sensors/scl_data',
        scl_polar_values: 'sensors/scl_polar_values',
        scl_acceleration_data: 'sensors/scl_acceleration_data',
        sensor_carga_data: 'sensors/sensor_carga_data',
        sensor_carga_sat_data: 'sensors/sensor_carga_sat_data',
        sensor_boya_data: 'sensors/sensor_boya_data',
        boya_polar_values: 'sensors/boya_polar_values',
        // sensor_carga: 'sensors/sensor_carga',
        // sensor_carga_sat: 'sensors/sensor_carga_sat',
        sensor_list_filter: 'sensors/sensor_list_filter',
        sensor_depth_filter: 'sensors/sensor_depth_filter',
        show_sensor_carga: 'sensors/show_sensor_carga',
        show_sensor_carga_sat: 'sensors/show_sensor_carga_sat',
        show_sensor_red: 'sensors/show_sensor_red',
        show_correntimetro: 'sensors/show_correntimetro',
        show_sensor_boya: 'sensors/show_sensor_boya'
    }), 
    selected_correntimetro: {
        set (value) { this.$store.dispatch('sensors/change_sensor_selected', ['correntimetro',value]) },
        get () { return this.$store.state.sensors.sensor_selected.correntimetro }
    },
    selected_sensor_red: {
        set (value) { this.$store.dispatch('sensors/change_sensor_selected', ['sensor_red',value]) },
        get () { return this.$store.state.sensors.sensor_selected.sensor_red }
    },
    selected_sensor_carga: {
        set (value) { this.$store.dispatch('sensors/change_sensor_selected', ['sensor_carga',value]) },
        get () { return this.$store.state.sensors.sensor_selected.sensor_carga }
    },
    selected_sensor_carga_sat: {
        set (value) { this.$store.dispatch('sensors/change_sensor_selected', ['sensor_carga_sat',value]) },
        get () { return this.$store.state.sensors.sensor_selected.sensor_carga_sat }
    },
    selected_sensor_boya: {
        set (value) { this.$store.dispatch('sensors/change_sensor_selected', ['sensor_boya',value]) },
        get () { return this.$store.state.sensors.sensor_selected.sensor_boya }
    },
  },
  watch: {
    correntimetro_data: function() {
      this.correntimetroDrawGraph('speed')
      this.correntimetroDrawGraph('dir')
      this.drawWindRoseGraph(this.correntimetro_select_depth)
    },
    scl_data: function() {
      this.drawGraph('scl',true)
      this.layout_scl = this.getLayout(this.scl_select_param)
      if(this.scl_select_param != 'depth'){
        this.drawPolarGraph('scl',this.scl_select_param)
        this.layout_polar_scl = this.getLayoutPolar(this.scl_select_param)
      }
    },
    scl_acceleration_data: function() {
      this.drawGraph('scl_acceleration',true)
      this.layout_scl_acceleration = this.getLayout(this.scl_acceleration_select_param)
    },
    sensor_carga_data: function() {
      this.drawGraph('sensor_carga',true)
      this.layout_sensor_carga = this.getLayout(this.sensor_carga_select_param)
    },
    sensor_carga_sat_data: function() {
      this.drawGraph('sensor_carga_sat',true)
      this.layout_sensor_carga = this.getLayout(this.sensor_carga_sat_select_param)
    },
    sensor_boya_data: function() {
      this.drawGraph('sensor_boya',true)
      this.layout_sensor_boya = this.getLayout(this.sensor_boya_select_param)
      if(this.sensor_boya_select_param == 'direction'){
        this.drawPolarGraph('boya',this.sensor_boya_select_param)
        this.layout_polar_boya = this.getLayoutPolar(this.sensor_boya_select_param)
      }
    },
    correntimetro_select_depth: function(){
      this.drawWindRoseGraph(this.correntimetro_select_depth)
    },
    scl_select_param: function() {
      this.drawGraph('scl',false)
      this.layout_scl = this.getLayout(this.scl_select_param)
      if(this.scl_select_param != 'depth'){
        this.drawPolarGraph('scl',this.scl_select_param)
        this.layout_polar_scl = this.getLayoutPolar(this.scl_select_param)
      }
    },
    scl_acceleration_select_param: function () {
      this.drawGraph('scl_acceleration',false)
      this.layout_scl_acceleration = this.getLayout(this.scl_acceleration_select_param)
    },
    sensor_carga_select_param: function () {
      this.drawGraph('sensor_carga',false)
      this.layout_sensor_carga = this.getLayout(this.sensor_carga_select_param)
    },
    sensor_carga_sat_select_param: function () {
      this.drawGraph('sensor_carga_sat',false)
      this.layout_sensor_carga = this.getLayout(this.sensor_carga_sat_select_param)
    },
    sensor_boya_select_param: function () {
      this.drawGraph('sensor_boya',false)
      this.layout_sensor_boya = this.getLayout(this.sensor_boya_select_param)
      if(this.sensor_boya_select_param == 'direction'){
        this.drawPolarGraph('boya',this.sensor_boya_select_param)
        this.layout_polar_boya = this.getLayoutPolar(this.sensor_boya_select_param)
      }
    },
    selected_correntimetro:function () {
      this.getLastData('correntimetro')
    },
    selected_sensor_red:function () {
      this.getLastData('sensor_red')
    },
    selected_sensor_carga:function () {
      this.getLastData('sensor_carga')
    },
    selected_sensor_carga_sat:function () {
      this.getLastData('sensor_carga_sat')
    },
    selected_sensor_boya:function () {
      this.getLastData('sensor_boya')
    },
  },
  methods: {
    getLayoutPolar(yaxisTitle) {
      return {
        polar: {
          radialaxis: {
            title: "", // Título del eje radial
          },
          angularaxis: {
            rotation: 90, // Rotación de las etiquetas angulares
            direction: 'clockwise', // Dirección de las etiquetas angulares
            period: 360, // Periodo en grados
            tickmode: 'array',
            tickvals: [0, 45, 90, 135, 180, 225, 270, 315], // Etiquetas de 0 a 315 grados
            ticktext: ['0°', '45°', '90°', '135°', '180°', '225°', '270°', '315°']
          }
        },
        showlegend: false,
        paper_bgcolor: '#f7f7f7'
      }
    },
    getLayout(yaxisTitle) {
      switch (yaxisTitle) {
        case 'pitch':
        case 'Dir':
        case 'roll':
        case 'heading':
        case 'direction':
          yaxisTitle = yaxisTitle + ' (°)'
          break;
        case 'depth':
          yaxisTitle = yaxisTitle + ' (m)'
          break;
        case 'weight':
          yaxisTitle = yaxisTitle + ' (kg)'
          break;
        case 'average_load':
        case 'max_load':
        case 'min_load':
          yaxisTitle = 'weight (kg)'
          break;
        case 'Speed':
          yaxisTitle = yaxisTitle + ' (m/s)'
          break;
        case 'significant_waveheight':
          yaxisTitle = 'significant waveheight' + ' (cm)'
          break;
        case 't1':
          yaxisTitle = yaxisTitle + ' (s)'
          break;
      }
      return {
        yaxis: {
          //autorange: 'reversed',
          title: {
          text: yaxisTitle
          },
        },
        xaxis: {
          title: {
            text: ''
          },
          // type: 'log',
          // autorange: true,
          // showexponent: 'all',
          // exponentformat: 'power'
        },
        plot_bgcolor:"#052030",
        paper_bgcolor:"#FFF3",
        dragmode: 'pan',
        modebar: {
          bgcolor: 'rgba(0,0,0,0)',
          color: '#555555',
          activecolor: '#bbbbbb',
          orientation: 'v'
        },
        //showlegend:true,
      
        autosize: true,
        height: 500,
        margin: {
          t: 10,
          r: 0
        },
        hoverlabel: {
          font: {
            color: 'white'
          }
        }
      }
    },
    correntimetroDrawGraph(graph_type) {
      var depth = this.sensor_depth_filter('correntimetro',this.selected_correntimetro) - 2.5  
      //var depth =  2.5  
      this['correntimetro_'+graph_type+'_data_graph'] = []
      for (let index = 1; index <= 20; index++) {

        var dates = this['correntimetro_data'].map(obj => obj.date_time);
        var values = this['correntimetro_data'].map(obj => obj[graph_type + '_'+ index]);
        var visible = "legendonly"
        if(index == 1)
          visible = true
        else if(index >= 13)
          visible = false
        var correntimetro_data_item = {
          x: dates,
          y: values,
          name: graph_type + '#'+ index + '(' + depth +'m)',
          mode: 'lines',
          visible: visible
        }
        this['correntimetro_'+graph_type+'_data_graph'].push(correntimetro_data_item);
        depth = depth - 2
      }
    },
    drawGraph(sensor_type,new_graph) {
      if (new_graph){
        var dates = this[sensor_type + '_data'].map(obj => obj.date_time);
        this[sensor_type + '_data_graph'][0].x = dates
      }
      var values = this[sensor_type + '_data'].map(obj => obj[this[sensor_type +'_select_param']]);
      this[sensor_type +'_data_graph'][0].y = values

      if (sensor_type == 'sensor_carga' || sensor_type == 'sensor_carga_sat') {
        var coloresBarras = values.map(asignarColor);
        this[sensor_type +'_data_graph'][0].marker = {color: coloresBarras }
      }
    },
    drawPolarGraph(sensor_type,param){
      var angulos = [];
      for (var i = 0; i < 360; i++) {
        angulos.push(i * 1); // Incremento de x grados
      }
      this[sensor_type +'_polar_graph'][0].theta = angulos
      this[sensor_type +'_polar_graph'][0].r = this[sensor_type + "_polar_values"][param]
    },
    drawWindRoseGraph(depth_index){
      var angulos = [];
      for (var i = 0; i < 16; i++) {
        angulos.push(i * 22.5); // Incremento de x grados
      }
      for (let i = 0; i < this.correntimetro_wind_rose_data_graph.length; i++) {
        this.correntimetro_wind_rose_data_graph[i].theta = angulos 
      }
      this.correntimetro_wind_rose_data_graph[5].r = this.correntimetro_wind_rose[depth_index][0]
      this.correntimetro_wind_rose_data_graph[4].r = this.correntimetro_wind_rose[depth_index][1]
      this.correntimetro_wind_rose_data_graph[3].r = this.correntimetro_wind_rose[depth_index][2]
      this.correntimetro_wind_rose_data_graph[2].r = this.correntimetro_wind_rose[depth_index][3]
      this.correntimetro_wind_rose_data_graph[1].r = this.correntimetro_wind_rose[depth_index][4]
      this.correntimetro_wind_rose_data_graph[0].r = this.correntimetro_wind_rose[depth_index][5]
    },
    getLastData(sensor_type){
      if (sensor_type == 'correntimetro')
        this.$store.dispatch('sensors/get_sensor_data', {sensor_type: 'scl', sensor_id: this.selected_correntimetro })
      else if (sensor_type == 'sensor_red'){
        this.$store.dispatch('sensors/get_sensor_data', {sensor_type: 'scl', sensor_id: this.selected_sensor_red })
     } else if (sensor_type == 'sensor_carga'){
        this.$store.dispatch('sensors/get_sensor_data', {sensor_type: 'sensor-carga', sensor_id: this.selected_sensor_carga })
      } else if (sensor_type == 'sensor_carga_sat'){
        this.$store.dispatch('sensors/get_sensor_data', {sensor_type: 'sensor-carga-sat', sensor_id: this.selected_sensor_carga_sat })
     } else if (sensor_type == 'sensor_boya'){
        this.$store.dispatch('sensors/get_sensor_data', {sensor_type: 'sensor-boya', sensor_id: this.selected_sensor_boya })
     }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>

.limit-text {
    overflow-y: hidden;
    overflow-x:hidden;
    text-overflow: ellipsis;
    max-height: 70px;      /* fallback */

}

.table-header{
    position:sticky;
    top: 0 ;
}
</style>